.aboutme {

  @include respond-to(tablet) {
    margin: 15px;
    width: 100%;
  }

  @include respond-to(medium-screen) {
    margin: 0 90px;
  }

  @include respond-to(large-screen) {
    margin: 0 150px;
  }

  .aboutme-wrapper {
    display: flex;
    justify-content: center;

    @include respond-to(tablet) {
      margin-left: 15px;
    }

    .manPic {
      height: 110px;
      margin-top: 60px;
      margin-right: -8px;
      z-index: 1;

      @include respond-to(mobile-landscape) {
        height: 160px;
      }

      @include respond-to(tablet) {
        margin-top: 60px;
        margin-right: -15px;
      }

      @include respond-to(screen) {
        height: 260px;
      }

      @include respond-to(medium-screen) {
        height: 100%;
      }
    }

    .imagePlaceholder {
      display: flex;
      font-family: BookmanOldStyle;
      align-items: center;
      font-weight: bold;
      text-align: center;
      color: #575756;
      background-color: #D9D9D9;
      width: 277px;
      height: auto;
      margin-bottom: 20px;

      @include respond-to(mobile-landscape) {
        width: 290px;
        margin-bottom: 30px;
      }

      @include respond-to(screen) {
        font-size: 18.5pt;
        line-height: 30pt;
        width: 420px;
        margin-bottom: 50px;
      }

      @include respond-to(medium-screen) {
        margin-bottom: 80px;
      }
    }

    li {
      list-style-position: outside;
      color: #BC1920;
      margin-left: 20px;
      font-size: 7pt;
      line-height: 2;

      @include respond-to(mobile-landscape) {
        font-size: 10pt;
        line-height: 20px;
        margin-bottom: 5px;
      }

      @include respond-to(tablet) {
        font-size: 10pt;
      }

      @include respond-to(screen) {
        font-size: 14pt;
        margin-left: 35px;
        margin-bottom: 20px;
      }

      @include respond-to(medium-screen) {
        margin-left: 60px;
        line-height: 30px;
        margin-bottom: 40px;
      }
    }
  }
}