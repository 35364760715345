/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  //font-size: 100%;
  //font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}
//ol, ul {
//  list-style: none;
//}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

.root {
  background-color: #BC1920;
}

.header {

  @include respond-to(tablet) {
    padding-bottom: 15px;
  }

  @include respond-to(medium-screen) {
    padding-bottom: 80px;
  }
}

.background {
  background-color: none;

  @include respond-to(tablet) {
    background-color: #BC1920;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
  }
}

.content-wrapper {
  background-color: white;
  border: 0 solid;
  padding: 10px;
  box-shadow: 0 5px 20px 0 #888888;
  margin: 20px 0;

  @include respond-to(tablet) {
    margin: -130px 95px -155px;
  }

  @include respond-to(screen) {
    margin: -125px 95px -155px;
  }

  @include respond-to(medium-screen) {
    margin: -185px 95px -255px;
  }

  h1 {
    font-family: BookmanOldStyle;
    color: #E5A80E;
    font-weight: bold;
    text-align: center;
    font-size: 20pt;
    margin-top: 15px;

    @include respond-to(tablet) {
      font-size: 37pt;
      margin: 50px 0;
      //margin-bottom: 178px;
    }

    @include respond-to(medium-screen) {
      font-size: 60pt;
    }

    @include respond-to(large-screen) {
      font-size: 80pt;
    }
  }

  h2 {
    color: #BC1920;
    font-weight: bold;
    font-size: 14pt;
    margin-bottom: 15px;
    font-family: BookmanOldStyle;

    @include respond-to(tablet) {
      font-size: 20pt;
      margin-bottom: 30px;
    }
    @include respond-to(screen) {
      font-size: 30px;
    }
  }

  .section {
    margin: 50px 0;

    @include respond-to(tablet) {
      display: flex;
      margin: unset;
    }

    @include respond-to(big-screen) {
      justify-content: center;
    }

    p {
      font-family: BookmanOldStyle;
      font-weight: bold;
      font-size: 8pt;
      line-height: 15pt;

      @include respond-to(tablet) {
        font-size: 10pt;
        line-height: 20pt;
      }

      @include respond-to(screen) {
        font-size: 14pt;
        line-height: 20pt;
      }
    }

    li {
      font-family: BookmanOldStyle;
      font-weight: bold;
      font-size: 10pt;

      @include respond-to(tablet) {
        font-size: 10pt;
      }

      @include respond-to(screen) {
        font-size: 14pt;
      }
    }
  }
}

a {
  color: #BC1920;
  text-decoration: none;
  font-weight: bold;
  font-family: BookmanOldStyle;

  @include respond-to(tablet) {
    font-size: 10pt;
  }

  @include respond-to(screen) {
    font-size: 13pt;
  }
}
