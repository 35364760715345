.kontakt {
  background-color: white;
  border: 0 solid;
  padding: 10px;
  box-shadow: 1px 1px 8px #888888;

  @include respond-to(tablet) {
    width: 100%;
    margin: 15px;
  }

  @include respond-to(medium-screen) {
    margin: 90px 50px;
    padding-left: 40px;
  }

  @include respond-to(large-screen) {
    margin: 90px 115px;
  }

  .kontakt-wrapper {
    display: flex;
    flex-direction: column;

    @include respond-to(medium-screen) {
      margin-left: -25px;
    }

    p {
      margin-bottom: 20px;

      &:first-child {
        color: #E5A80E;
      }

      &:last-child {
        color: #BC1920;
      }
    }
  }
}