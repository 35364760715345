.nav-bar {
  display: none;
  background-color: white;
  justify-content: space-around;
  padding: 25px 0 150px;

  img {

    @include respond-to(tablet) {
      height: 45px;
    }

    @include respond-to(screen) {
      height: 70px;
    }
  }

  @include respond-to(tablet) {
    display: flex;
  }
}

.link-wrapper {
  align-items: center;
  display: flex;

  div {

    @include respond-to(tablet) {
      margin-right: 15px;
    }

    @include respond-to(screen) {
      margin-right: 15px;
    }

    @include respond-to(medium-screen) {
      margin-right: 30px;
    }

    @include respond-to(large-screen) {
      margin-right: 70px;
    }

    &:last-child {
      margin-right: unset;
    }
  }
}