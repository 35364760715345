.aktuell {

  @include respond-to(tablet) {
    margin: 15px;
    width: 100%;
  }

  @include respond-to(medium-screen) {
    margin: 50px 90px;
  }

  @include respond-to(large-screen) {
    margin: 50px 150px;
  }

  .aktuell-wrapper {
    //display: flex;
    //flex-direction: column;

    @include respond-to(medium-screen) {
      margin-left: -25px;
    }

    div {
      display: flex;
      align-items: baseline;
      margin-bottom: 10px;

      @include respond-to(mobile-landscape) {
        justify-content: space-between;
      }

      @include respond-to(tablet) {
        justify-content: unset;
      }
    }

    span {
      font-family: BookmanOldStyle;
      color: #E5A80E;
      font-weight: bold;
      font-size: 8pt;
      line-height: 30pt;
      width: 100px;
      flex-shrink: 0;

      @include respond-to(mobile-landscape) {
        font-size: 11pt;
      }

      @include respond-to(tablet) {
        font-size: 12pt;
        width: 120px;
      }
      @include respond-to(screen) {
        font-size: 14pt;
        width: 150px;
      }
    }

    p {
      color: #BC1920;
      font-size: 8pt;
      flex: auto;

      @include respond-to(mobile-landscape) {
        font-size: 10pt;
      }
      @include respond-to(screen) {
        font-size: 12pt;
      }
    }
  }
}