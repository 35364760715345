.praxis {

  @include respond-to(tablet) {
    margin: 15px;
  }

  @include respond-to(medium-screen) {
    margin: 90px;
  }

  @include respond-to(large-screen) {
    margin: 90px 150px;
  }

  .image-wrapper {
    display: flex;

    img {
      width: 100%;
    }
  }
}