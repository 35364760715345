.leistungen {

  @include respond-to(tablet) {
    margin: 15px;
    width: 100%;
  }

  @include respond-to(medium-screen) {
    margin: 0 90px;
  }

  @include respond-to(large-screen) {
    margin: 0 150px;
  }

  .leistungen-wrapper {
    display: flex;
    position: relative;
    margin-bottom: 30px;

    @include respond-to(medium-screen) {
      margin-left: -25px;
    }

    div {
      width: 100%;
      z-index: 1;

      &:first-child {
        margin-right: 15px;
        padding-right: 10px;
        border-right: 2px solid #E5A80E;

        @include respond-to(tablet) {
          margin-right: 40px;
          margin-left: 15px;
        }
        @include respond-to(screen) {
          margin-left: unset;
        }
      }
    }

    h3 {
      font-family: BookmanOldStyle;
      color: #E5A80E;
      font-weight: bold;
      font-size: 7pt;
      margin-bottom: 20px;

      @include respond-to(mobile-landscape) {
        width: 250px;
        font-size: 10pt;
      }

      @include respond-to(tablet) {
        font-size: 12pt;
        margin-bottom: 40px;
      }

      @include respond-to(screen) {
        font-size: 16pt;
        margin-bottom: 40px;
        width: 340px;
      }

      @include respond-to(medium-screen) {
        width: 500px;
      }

      @include respond-to(large-screen) {
        width: 615px;
      }
    }

    ul {
      list-style: none;

      li {
        color: #BC1920;
        font-size: 6pt;
        line-height: 2;

        @include respond-to(mobile-landscape) {
          font-size: 10pt;
          line-height: 20px;
          margin-bottom: 20px;
        }

        @include respond-to(tablet) {
          font-size: 10pt;
        }

        @include respond-to(screen) {
          font-size: 12pt;
          line-height: 30px;
          margin-bottom: 20px;
        }

        a {
          text-decoration: underline;
          font-size: unset;
        }
      }
    }

    img {
      position: absolute;
      height: auto;
      right: 0;
      width: 35%;

      @include respond-to(mobile-landscape) {
        width: 45%;
      }

      @include respond-to(tablet) {
        width: 45%;
        right: 5px;
      }

      @include respond-to(screen) {
        width: 38%;
        right: 100px;
      }

      @include respond-to(large-screen) {
        width: 28%;
        right: 250px;
      }
      @include respond-to(big-screen) {
        width: 23%;
      }
    }
  }
  p {
    color: #BC1920;
    position: relative;

    @include respond-to(medium-screen) {
      margin-left: -25px;
    }

    @include respond-to(tablet) {
      margin-left: 15px;
    }
    @include respond-to(screen) {
      margin-left: unset;
    }
    @include respond-to(medium-screen) {
      margin-left: -25px;
    }
  }

  h2 {
    @include respond-to(tablet) {
      margin-left: 15px;
    }
    @include respond-to(screen) {
      margin-left: unset;
    }
  }
}