/*------------------------------------*\
    _mixins.scss
\*------------------------------------*/


/*------------------------------------*\
    @mixin respond-to
\*------------------------------------*/
@mixin respond-to($media) {
  @if $media == mobile-landscape {
    @media only screen and (min-width: $break-mobile-l) { @content; }
  }
  @else if $media == tablet {
    @media only screen and (min-width: $break-tablet) { @content; }
  }
  @else if $media == screen {
    @media only screen and (min-width: $break-screen) { @content; }
  }
  @else if $media == medium-screen {
    @media only screen and (min-width: $break-screen-m) { @content; }
  }
  @else if $media == large-screen {
    @media only screen and (min-width: $break-screen-l) { @content; }
  }
  @else if $media == big-screen {
    @media only screen and (min-width: $break-screen-b) { @content; }
  }
}