/*------------------------------------*\
    _config.scss
\*------------------------------------*/
@font-face {
  font-family: 'BookmanOldStyle';
  src: url('./fonts/BookmanOldStyle.ttf') format('truetype');
}

/*------------------------------------*\
    :: BREAK POINTS
\*------------------------------------*/
$break-mobile-l: 568px;
$break-tablet: 768px;
$break-screen: 1024px;
$break-screen-m: 1281px;
$break-screen-l: 1486px;
$break-screen-b: 1996px;