.map {

  @include respond-to(tablet) {
    margin: 15px;
    width: 100%;
  }

  @include respond-to(medium-screen) {
    margin: 0 90px;
  }

  @include respond-to(large-screen) {
    margin: 0 150px;
  }

  .map-wrapper {
    display: flex;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;

      @include respond-to(tablet) {
        width: 70%;
      }
    }
  }

  p {
    color: #BC1920;
    margin-top: 30px;

    @include respond-to(medium-screen) {
      margin-left: -25px;
    }
  }
}