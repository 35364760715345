.footer {

  @include respond-to(tablet) {
    padding-top: 55px;
  }

  @include respond-to(medium-screen) {
    padding-top: 110px;
  }


  div {
    margin: 0 0 10px 15px;

    @include respond-to(tablet) {
      margin: 40px 0 40px 100px;
    }
  }
}