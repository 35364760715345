.impressum {
  width: 100%;

  @include respond-to(tablet) {
    margin: 15px;
  }

  @include respond-to(medium-screen) {
    margin: 90px;
  }

  @include respond-to(large-screen) {
    margin: 90px 150px;
  }

  p {
    color: #E5A80E;
    margin-bottom: 30px;

    @include respond-to(medium-screen) {
      margin-left: -25px;
    }
  }

  .impressum-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @include respond-to(medium-screen) {
      margin-left: -25px;
    }

    li {
      color: #BC1920;
      list-style: none;
      line-height: 10px;
      font-size: 4pt;

      @include respond-to(mobile-landscape) {
        font-size: 8pt;
        line-height: 20px;
      }

      @include respond-to(tablet) {
        font-size: 8pt;
      }

      @include respond-to(screen) {
        font-size: 12pt;
        line-height: 25px;
      }

      @include respond-to(medium-screen) {
        line-height: 25px;
      }
    }
  }
}