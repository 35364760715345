.partner {
  width: 100%;

  @include respond-to(tablet) {
    margin: 15px;
  }

  @include respond-to(medium-screen) {
    margin: 0 90px 90px;
  }

  @include respond-to(large-screen) {
    margin: 0 90px 50px 150px;
  }

  .partner-wrapper {
    display: flex;

    @include respond-to(medium-screen) {
      margin-left: -25px;
    }

    ul {
      &:first-child {
        margin-left: 15px;

        @include respond-to(tablet) {
          margin-left: 20px;
        }
      }
    }

    li {
      color: #BC1920;
      margin-right: 20px;

      @include respond-to(tablet) {
        font-size: 16pt;
        margin-right: 100px;
      }
    }
  }
}