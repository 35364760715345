/*------------------------------------*\
    _privacy.scss
\*------------------------------------*/

/*------------------------------------*\
    :: Datenschutz
\*------------------------------------*/

.section-privacy {
  margin: 50px;
  color: #fff;

  h1, h2, h3 {
    color: #000;
    margin: 15px 0;
  }

  p {
    color: #000;
    font-size: 12pt;
    line-height: 1.6;
  }

  a {
    color: #000;
  }

  li {
    color: #000;
    list-style: inside;
  }

  button {
    text-decoration: underline;
    background-color: transparent;
    color: #000;
    border: none;
    margin: 15px 0;
    font-size: 12pt;
  }
}