.header {
/* Position and sizing of burger button */

.bm-burger-button {
  height: 30px;
  left: 36px;
  margin: 30px 0;
  position: absolute;
  top: 10px;
  width: 36px;

  @include respond-to(tablet) {
    display: none;
  }
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background-color: #E5A80E;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  //background: blue;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #BC1920;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  height: 100%;
  position: fixed;
  top: 0;
}

/* General sidebar styles */
.bm-menu {
  background: white;
  font-size: 1.15em;
  padding: 2.5em 1.5em 0;
}

/* Wrapper for item list */
.bm-item-list {
  //color: red;
}

/* Styling of overlay */
.bm-overlay {
  top: 0;
}

.right .bm-burger-button {
  left: initial;
  padding: 30px;
  right: 36px;
}

.bm-morph-shape {
  //fill: yellow;
}

.bm-menu {
  background: white;

  a {
    color: #BC1920;
    border-bottom: 1px solid;

    &:hover,
    &:focus {
      color: #E5A80E;
      cursor: pointer;
    }
  }
}

.bm-item-list a {
  padding: 0.8em;

  span {
    font-weight: 700;
    margin-left: 10px;
  }
}

.bm-item:focus {
  outline: none;
}

.physioLogoMobile {
  display: block;
  width: 60%;
  margin-top: 30px;
  margin-left: 30%;

   @include respond-to(tablet) {
     display: none;
   }
}
}